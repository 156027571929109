import React, { FC } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import { Container } from 'gatsby-theme-husky/src/layout';
import BannerTextSection from 'gatsby-theme-husky/src/components/BannerTextSection';

import { TBannerComponentProps } from 'gatsby-theme-husky/src/components/HeroBanner/models';
import 'gatsby-theme-husky/src/components/HeroBanner/HeroBanner.scss';
import 'gatsby-theme-husky/src/components/HeroBanner/HeroBannerRTL.scss';
import './HeroBannerExtends.scss';

const HeroBanner: FC<TBannerComponentProps> = ({
  altImageText,
  image,
  url,
  title,
  description,
  button,
  hasVerticalPadding = false,
  className,
  inFirstViewport,
  reversed = false,
  bannerTheme,
  headerLevel,
}) => {
  return (
    <Container fluid>
      <div
        className={classNames(
          'hero-wrapper',
          {
            'hero-wrapper--vertical-padding': hasVerticalPadding,
            'hero-wrapper--reversed': reversed,
            [`${bannerTheme}`]: bannerTheme,
          },
          className
        )}
      >
        <div className="hero-banner">
          <BannerTextSection
            button={button}
            description={description}
            title={title}
            url={url}
            headerLevel={headerLevel}
          />
          <div className="hero-banner__image-wrapper">
            {image?.childImageSharp ? (
              <GatsbyImage
                isLazyLoading={!inFirstViewport}
                fluid={image.childImageSharp.fluid}
                alt={altImageText}
                fadeIn={!inFirstViewport}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeroBanner;

export const query = graphql`
  fragment FragmentHeroBanner on HeroBannerTypes {
    properties {
      title
      imageAlt
      image {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      button {
        ...FragmentButton
      }
    }
  }
`;
